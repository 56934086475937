@use 'sass:map';

@use '../../../styles/colors.scss' as colors;
@use '../../../styles/sizes.scss' as sizes;
@use '../../../styles/media-queries.scss' as mediaQueries;
@use '../../../styles/transitions.scss' as transitions;

.rootContainer {
  display: flex;
  align-items: center;
  height: map.get(sizes.$header, 'height') + rem;
  padding-left: map.get(sizes.$header, 'paddingLeft') + rem;
  padding-right: map.get(sizes.$header, 'paddingRight') + rem;

  background-color: #ffffff10;

  .ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;

    list-style-type: none;

    & > li {
      display: flex;
      align-items: center;

      &.hidden {
        opacity: 0;
      }

      &:nth-child(1) {
        time {
          font-size: 1.4rem;
          line-height: 2.4rem;
          color: #ffffff70;

          .date {
            font-weight: 600;
          }

          .separator {
            display: inline-flex;
            margin: 0 0.8rem;
            height: 1.6rem;

            color: #ffffff30;
          }

          .time {
            font-weight: 400;
          }
        }
      }

      &:nth-child(2) {
        color: colors.$white;
        font-weight: 800;
        font-size: 2.4rem;
        line-height: 4rem;

        @include mediaQueries.md {
          display: none;
        }

        p {
          margin: 0 0 0 1.6rem;
        }
      }

      &:nth-child(3) {
        .profileData {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 0.4rem 2.4rem 0.4rem 0.4rem;
          height: 4.8rem;

          cursor: pointer;
          border-radius: 24px;
          background-color: #ffffff10;

          img {
            width: 4rem;
            height: 4rem;

            border-radius: 50%;
          }

          p {
            margin-left: 0.8rem;

            transition: transitions.$transitionFour;
            color: colors.$white;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 2.4rem;
            opacity: 1;

            &:empty {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
