@use 'sass:map';
@use '../../../styles/transitions.scss' as transitions;

$thumbWidth: 1.6rem;
$thumbHeight: 1.6rem;

.rootContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;

  &.thin {
    .lineAndThumbContainer {
      .line {
        height: 0.15rem;
        background-color: #9cabc2;
      }

      .thumbContainer {
        .thumb {
          &::after {
            background-color: #333333;
          }
        }
      }
    }

    .levelsContainer {
      margin: 0.8rem 0 0;
    }
  }

  &.thick {
    .lineAndThumbContainer {
      .line {
        height: 0.8rem;

        border-radius: 35px;
        background: linear-gradient(
          to right,
          #ffa800,
          #ffd600,
          #f7f7f7,
          #339dea,
          #3371ea
        );
      }

      .thumbContainer {
        .thumb {
          background-color: #82c2f0;

          transition: background-color
            map.get(transitions.$transitionDuration, 'fastest') ease-in-out;

          &::after {
            background-color: #ffffff;
          }
        }
      }
    }

    .levelsContainer {
      margin: 0.4rem 0 0;
    }
  }

  .labelsContainer {
    display: flex;
    justify-content: space-between;

    margin: 0 0 0.8rem;
    padding: 0;

    list-style: none;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.56rem;
    color: #000000;
  }

  .lineAndThumbContainer {
    position: relative;
    display: flex;
    align-items: center;

    height: $thumbHeight;

    .line {
      width: 100%;
    }

    .thumbContainer {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-50%);

      .thumb {
        position: relative;
        width: $thumbWidth;
        height: $thumbHeight;

        cursor: pointer;
        border-radius: 50%;
        opacity: 0; // Avoid flickering effect initially

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0.8rem;
          height: 0.8rem;

          border-radius: 50%;
        }
      }
    }
  }

  .levelsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;

    list-style: none;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.44rem;
    color: #000000;
  }
}
