@use '../../../styles/colors.scss' as colors;
@use '../common-styles/form-hint-and-error.scss';

.rootContainer {
  color: colors.$red;

  @extend %rootContainer;

  span {
    @extend %textContainer;
  }
}
