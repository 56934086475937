@use '../../../styles/media-queries.scss' as mediaQueries;
@use '../../../styles/transitions.scss' as transitions;
@use '../../../styles/scrollbar.scss';

.rootContainer {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 2.4rem 2.4rem 2.1rem 2.4rem;
  margin: 0 0 0 1.8rem;

  border-radius: 24px;
  background-color: #12285e;

  .header {
    display: flex;
    align-items: center;

    width: 100%;

    h1 {
      margin: 0 0 0 1.2rem;
      min-height: 2.209rem;

      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.6rem;
      color: #ffffff;
    }
  }

  .separatorContainer {
    display: flex;
    align-items: center;
    margin: 0.8rem 0;

    .separator {
      width: 100%;
      height: 0.1rem;

      background-color: #ffffff20;
    }

    p {
      display: flex;

      margin: 0 1.4rem;

      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: #ffffff50;
    }
  }

  .chatMessageAndInputContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;

    height: 100%;

    .chatMessages {
      display: flex;
      flex-direction: column-reverse;
      overflow-y: auto;

      flex: 1;
      margin: 0;
      padding: 0;

      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #ffffff;

      @extend %scrollbar;
      @extend %scrollbarTrack;
      @extend %scrollbarThumb;

      & > li {
        &:nth-last-child(n + 2),
        &.spinnerContainer.active {
          padding-top: 2rem;
        }

        &:nth-child(n + 2) {
          padding-bottom: 2rem;
          border-bottom: 1px solid #ffffff20;
        }

        &.spinnerContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0;
          height: 0rem;

          transition: transitions.$transitionThree;
          opacity: 0;

          &.active {
            height: 6rem;
            opacity: 1;
          }

          .spinner {
            width: 4rem;
            height: 4rem;
          }
        }

        p {
          margin: 0;
        }
      }
    }

    .inputAndButtonContainer {
      margin-top: 2.9rem;
      width: 100%;

      .inputAndButtonInnerContainer {
        display: flex;
      }
    }
  }
}
