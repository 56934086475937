@use 'sass:map';
@use '../../../styles/sizes.scss' as sizes;

.rootContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  bottom: 0;
  width: 100%;
  height: map.get(sizes.$footer, 'height') + rem;

  background-color: transparent;

  svg {
    margin: 0 1rem;
  }
}
