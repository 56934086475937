.row {
  display: flex;
  width: 100%;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex: calc($i / 12);

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
