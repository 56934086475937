@use 'sass:map';

@use './sizes.scss' as sizes;
@use './media-queries.scss' as mediaQueries;

html {
  font-size: 10px;
  font-family: 'Inter';

  &,
  & > body,
  body > #root,
  body > #root > .rootContainer {
    height: 100%;
  }

  body {
    margin: 0;

    background-image: url(../assets/images/mixed/page-background.svg);
    background-repeat: no-repeat;
    background-size: cover;

    & > #root {
      & > .rootContainer {
        & main {
          $headerHeight: map.get(sizes.$header, 'height') + rem;
          $footerHeight: map.get(sizes.$footer, 'height') + rem;
          height: calc(100% - $headerHeight - $footerHeight);
        }
      }
    }
  }
}
