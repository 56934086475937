@mixin xlg {
  @media screen and (max-width: 1400px) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin xsm {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin lgHeight {
  @media screen and (max-height: 1000px) {
    @content;
  }
}

@mixin mdHeight {
  @media screen and (max-height: 800px) {
    @content;
  }
}

@mixin smHeight {
  @media screen and (max-height: 600px) {
    @content;
  }
}

@mixin xsmHeight {
  @media screen and (max-height: 400px) {
    @content;
  }
}
