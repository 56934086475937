.rootContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4rem;
    max-width: 40rem;
    width: 100%;

    border-radius: 24px;
    background: linear-gradient(#031b3750, #402e7250);

    h1 {
      text-align: center;

      margin: 0 0 3.5rem;

      font-size: 2.5rem;
      color: #ffffff;
    }

    input {
      font-size: 1.7rem;

      &:nth-of-type(2) {
        margin-top: 2rem;
      }
    }

    button {
      margin-top: 4rem;
      max-width: 100%;
      width: 100%;

      color: #ffffff;
      font-size: 1.7rem;
    }
  }
}
