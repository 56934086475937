.rootContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 1.4rem;
  width: 100%;
  height: 2.4rem;

  .name {
    margin: 0;

    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.4rem;
    color: #2d3e55;
  }
}
