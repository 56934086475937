/**
 * @description This component is a slightly altered copy of
 * https://codepen.io/niroh/pen/xxBYvGL.
 */

* {
  padding: 0;
  margin: 0;
}

/* Loader CSS */
$loaderSize: 100px;
$loaderSpeed: 1.4s;
$borderThickness: 5px;
$loaderBaseColor: rgb(187, 0, 255);

.loadContainer {
  position: relative;
  width: $loaderSize;
  height: $loaderSize;
}

.loadInner {
  width: 82.5%;
  height: 85%;
  border-radius: 100%;
  position: absolute;
  top: 7.5%;
  left: 7.25%;
}

.loadCircle {
  width: 100%;
  height: 100%;
  position: absolute;
}

.loadCircle > .loadCircleInner {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: $borderThickness solid rgba($loaderBaseColor, 0.7);
  border-right: none;
  border-top: none;
  background-clip: padding;
  box-shadow: inset 0px 0px 10px rgba($loaderBaseColor, 0.15);
}
.loadCircle:nth-of-type(0) {
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
}
.loadCircle:nth-of-type(0) .loadCircleInner {
  animation: loaderSpin $loaderSpeed infinite linear;
  -o-animation: loaderSpin $loaderSpeed infinite linear;
  -ms-animation: loaderSpin $loaderSpeed infinite linear;
  -webkit-animation: loaderSpin $loaderSpeed infinite linear;
  -moz-animation: loaderSpin $loaderSpeed infinite linear;
}
.loadCircle:nth-of-type(1) {
  transform: rotate(70deg);
  -o-transform: rotate(70deg);
  -ms-transform: rotate(70deg);
  -webkit-transform: rotate(70deg);
  -moz-transform: rotate(70deg);
}
.loadCircle:nth-of-type(1) .loadCircleInner {
  animation: loaderSpin $loaderSpeed infinite linear;
  -o-animation: loaderSpin $loaderSpeed infinite linear;
  -ms-animation: loaderSpin $loaderSpeed infinite linear;
  -webkit-animation: loaderSpin $loaderSpeed infinite linear;
  -moz-animation: loaderSpin $loaderSpeed infinite linear;
}
.loadCircle:nth-of-type(2) {
  transform: rotate(140deg);
  -o-transform: rotate(140deg);
  -ms-transform: rotate(140deg);
  -webkit-transform: rotate(140deg);
  -moz-transform: rotate(140deg);
}
.loadCircle:nth-of-type(2) .loadCircleInner {
  animation: loaderSpin $loaderSpeed infinite linear;
  -o-animation: loaderSpin $loaderSpeed infinite linear;
  -ms-animation: loaderSpin $loaderSpeed infinite linear;
  -webkit-animation: loaderSpin $loaderSpeed infinite linear;
  -moz-animation: loaderSpin $loaderSpeed infinite linear;
}
.loadInner {
  animation: loaderSpin ($loaderSpeed * 2.5) infinite linear;
  -o-animation: loaderSpin ($loaderSpeed * 2.5) infinite linear;
  -ms-animation: loaderSpin ($loaderSpeed * 2.5) infinite linear;
  -webkit-animation: loaderSpin ($loaderSpeed * 2.5) infinite linear;
  -moz-animation: loaderSpin ($loaderSpeed * 2.5) infinite linear;
}
@keyframes loaderSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loaderSpin {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes loaderSpin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-webkit-keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes loaderSpin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
