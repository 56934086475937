@use 'sass:map';

$transitionDuration: (
  fastest: 110ms,
  fast: 150ms,
  slow: 250ms,
  reallySlow: 500ms
);
$transitionOne: all map.get($transitionDuration, 'fastest') ease-in-out;
$transitionTwo: all map.get($transitionDuration, 'fast') ease-in-out;
$transitionThree: all map.get($transitionDuration, 'slow') ease-in-out;
$transitionFour: all map.get($transitionDuration, 'reallySlow') ease-in-out;
