%scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
}

%scrollbarTrack {
  &::-webkit-scrollbar-track {
    background-color: #41547e;
  }
}

%scrollbarThumb {
  &::-webkit-scrollbar-thumb {
    background-color: #a2acc6;
    border-radius: 0.2rem;
  }
}
