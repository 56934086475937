@use 'sass:map';

@use '../styles/media-queries.scss' as mediaQueries;
@use '../styles/transitions.scss' as transitions;
@use '../styles/sizes.scss' as sizes;
@use '../styles/scrollbar.scss';

.rootContainer {
  display: flex;
  flex-direction: column;

  margin-top: 5.6rem;
  height: 100%;

  .devicesAndChatContainer {
    display: flex;

    height: 59rem;

    .devicesOuterContainer {
      overflow-y: auto;

      flex: 1 100.8rem;
      padding: 2.2rem;

      border-radius: 24px;
      background: linear-gradient(#031b3750, #402e7250);

      @include mediaQueries.md {
        flex: 1;
      }

      @extend %scrollbar;
      @extend %scrollbarTrack;
      @extend %scrollbarThumb;

      .devicesContainer {
        display: grid;

        grid-template-columns: repeat(3, 1fr);
        gap: 2.4rem 2.2rem;
        height: fit-content;

        @include mediaQueries.md {
          grid-template-columns: repeat(1, 1fr);
        }

        .devicePair {
          position: relative;
          height: fit-content;
          transform-style: preserve-3d;

          transition: transitions.$transitionFour;

          &.devicePowerStatusOff {
            transform: rotateY(180deg);
          }

          &.devicePowerStatusOn {
            transform: rotateY(0deg);
          }

          &.disableTransition {
            transform: none;
          }

          .secondDevice {
            position: absolute;
            backface-visibility: hidden;

            transform: rotateY(180deg);
            top: 0;
            left: 0;
            $deviceCornerPaddingsSum: map.get(sizes.$device, 'paddingRight') +
              map.get(sizes.$device, 'paddingLeft') + rem;
            width: calc(100% - $deviceCornerPaddingsSum);
          }
        }
      }
    }

    .chat {
      flex: 1 35rem;

      @include mediaQueries.md {
        flex: 1;
      }
    }
  }
}
