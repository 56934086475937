%rootContainer {
  display: flex;
  margin: 0.3rem 0 0;

  font-size: 1.4rem;
}

%textContainer {
  margin-left: 0.5rem;
}
