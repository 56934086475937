@use 'sass:map';
@use '../../../styles/transitions.scss' as transitions;

.rootContainer {
  display: flex;

  &.disabled {
    label {
      cursor: initial;
    }
  }

  input {
    width: 0;
    height: 0;

    &:checked {
      & + label {
        background-color: #34c85a;

        &::after {
          left: calc(100% - 0.3rem);
          transform: translateX(-100%);
        }
      }
    }
  }

  label {
    display: flex;
    position: relative;
    width: 4.8rem;
    height: 2.4rem;

    transition: all map.get(transitions.$transitionDuration, 'slow') ease-in-out;
    cursor: pointer;
    border-radius: 1.3rem;
    background-color: #c4ccda;

    &::after {
      content: '';
      position: absolute;
      top: 0.4rem;
      left: 0.4rem;
      width: 1.6rem;
      height: 1.6rem;

      border-radius: 2rem;
      background-color: #ffffff;
      transition: map.get(transitions.$transitionDuration, 'slow') ease-in-out;
    }
  }
}
