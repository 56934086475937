@use 'sass:map';
@use '../../styles/sizes.scss' as sizes;

.rootContainer {
  padding: 2.4rem map.get(sizes.$device, 'paddingRight') + rem 3.26rem
    map.get(sizes.$device, 'paddingLeft') + rem;

  user-select: none;
  border-radius: 8px;
  background-color: #ffffff;

  .ul {
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      display: flex;
    }
  }
}
