@use '../../../styles/transitions.scss' as transitions;

.input {
  box-sizing: border-box;

  padding: 1.5rem 1.5rem 1.5rem 1.6rem;
  width: 100%;
  height: 4.8rem;

  transition: transitions.$transitionThree;
  outline: none;
  border: none;
  border-radius: 8px;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  background-color: #ffffff;
  color: #333333;
}
