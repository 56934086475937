@use '../../../styles/transitions.scss' as transitions;

@keyframes pulseAnimation {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 0.8rem;
  padding: 1rem;
  max-width: 4.8rem;
  width: 100%;
  height: 4.8rem;

  transition: transitions.$transitionThree;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: #34c85a;

  &:hover {
    background-color: darken(#34c85a, 10);
  }

  &:disabled {
    background-color: darken(#34c85a, 20);
  }

  &.playPulseAnimation {
    animation: pulseAnimation 1.3s infinite;
  }
}
