.iconAndAdditionalInfo {
  display: flex;
  align-items: center;

  margin: 3.3rem 0 0 1.4rem;
  width: 100%;
  height: 3rem;

  &.smartBlinds,
  &.smartTV,
  &.smartSpeaker,
  &.smartKettle {
    margin-top: 3.3rem;
    height: 2.64rem;
  }

  &.smartLightbulb {
    margin-top: 2.9rem;
    height: 3rem;
  }

  &.hvac {
    margin-top: 4rem;
    height: 2rem;
  }

  .deviceStatusText {
    margin: 0 0 0 0.88rem;

    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.54rem;
    color: #000000;
  }

  .hvacRootContainer {
    display: flex;
    align-items: center;

    .temperatureSetpoint {
      margin: 0 0 0 0.8rem;

      font-weight: 600;
      font-size: 1.8rem;
      line-height: 1.54rem;
      color: #000000;
    }

    .statusIndicatorTwoContainer {
      margin-left: 0.8rem;
    }

    .statusLabel {
      margin: 0 0 0 0.2rem;

      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.54rem;
      color: #000000;
    }
  }
}
