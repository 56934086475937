@use 'sass:map';
@use './styles/sizes.scss' as sizes;

.rootContainer {
  position: relative;

  main {
    padding-left: map.get(sizes.$header, 'paddingLeft') + rem;
    padding-right: map.get(sizes.$header, 'paddingRight') + rem;
  }
}
