.no-select {
  user-select: none;
}

.hidden {
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: none;
}
